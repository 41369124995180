/* eslint-disable */
<template>
  <div class="error-page">
    <div class="right-container">
      <img src="../assets/img/ozo_login.svg" alt="" width="67" height="61" class="logo">
      <img src="../assets/img/error_circle.svg" alt="" class="error-img">

      <div class="text-container">
        <h1 class="error-title">Error</h1>
        <p>Произошла ошибка.</p>
        <p>Вы не имеете необходимых ролей для доступа к этой странице.</p>
        <button class="button">Вернуться на главную</button>
      </div>

    </div>

    <img src="../assets/img/error_icon.svg" alt="" style="margin-top: 170px;">

    <!-- <p>
      Произошла ошибка. Вы не имеете необходимых ролей для доступа к этой
      странице.
    </p>
    <v-btn @click="handleReturnAndSignOut">Вернуться на главную страницу и Выход</v-btn> -->
  </div>
</template>

<script>
import { mapActions } from "vuex";
/* eslint-disable */
import { getAuth, signOut } from "firebase/auth";
/* eslint-disable */
export default {
  name: "ErrorPage",
  methods: {
    ...mapActions(["emptyUser"]),
    async handleReturnAndSignOut() {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        /* eslint-disable */
        await signOut(auth); // Вызываем функцию без точки с запятой и без .apply
        this.emptyUser();
      } else {
        console.log("Пользователь не залогинен.");
      }

      // Добавляем задержку перед перенаправлением
      const delayMilliseconds = 2000; // Пример: 2 секунды
      setTimeout(() => {
        this.$router.push({ name: "Login" }); // Перенаправляем после задержки
      }, delayMilliseconds);
    },
  },
};
</script>

<style scoped>
.error-page {
  max-width: 1440px;
  margin: 0 auto;
  padding: 40px;
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: space-between;

}

.right-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.text-container {
  text-align: start;
}

.text-container p {
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  text-align: left;
  max-width: 346px;
}

.text-container :nth-child(2) {
  margin-bottom: 30px;
}

.text-container :nth-child(3) {
  margin-bottom: 40px;

}

.logo {
  margin-bottom: 100px;
}

.error-title {
  font-family: Inter;
  font-size: 64px;
  font-weight: 700;
  line-height: 77.45px;
}

.button {
  padding: 5px 18px 5px 18px;
  border-radius: 5px;
  opacity: 0px;
  background: #323C4D;
  color: #fff;

  line-height: 26.25px;


}

.error-img {
  margin-bottom: 40px;
}
</style>
